<template>
	<div>
		<div class="page">
			<top />
			<div class="list">
				<div class="part" v-for="(item,index) in list" @click="todetail(item.ask_id)">
					<div class="part-info flex">
						<div class="left">
							<img :src="imageUrl(item.head_pic)" alt="">
						</div>
						<span class="name">{{item.nickname}}</span>
					</div>
					<div>
						<div class="title">{{item.ask_title}}</div>
						<div class="img-list flex sb" v-if="item.thumb">
							<img :src="imageUrl(item.thumb)" alt="">
						</div>
						<div class="info">
							<span>阅读 {{item.click}}</span>
							<span>发布时间 {{item.add_time}}</span>
						</div>
					</div>
				</div>
				<div class="pages">
					<el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="size"
						layout="total, prev, pager, next ,jumper" :pager-count="5" :total="count">
					</el-pagination>
				</div>
				<div class="dr-top">
					<div class="discussform dt-top-btn flex" @click="topulbc">
						<img style="margin-right: 10px;width: 16px;" src="../../static/edit.png" alt="">
						<span>发布资讯</span>
					</div>
					<div class="dt-top-text">发布资讯来分享、交流、提问</div>
				</div>
			</div>
			<info />
			<div style="width: 100%; height: 60px; background-color: #f6f6f6"></div>
			<foot />
		</div>
	</div>
</template>
<script>
	import top from "../../components/common/header.vue";
	import foot from "../../components/common/footer.vue";
	import info from "../../components/common/qrCode.vue";
	import {
		askindex,
	} from "../../request/api";
	export default {
		components: {
			top,
			foot,
			info,
		},
		created() {
			this.getaskindex()
		},
		data() {
			return {
				count: 1,
				size: 20,
				page: 1,
				list: ''
			};
		},
		methods: {
			getaskindex() {
				askindex({
					page: this.page
				}).then((res) => {
					console.log(res);
					this.list = res.data.list;
					this.count = res.data.count
				});
			},
			//公开区跳转
			goOpenArea(id) {
				console.log(id);
				this.$router.push({
					path: `/openArea/${id}`,
				});
			},
			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.getaskindex();
			},
			//选项
			change(val) {
				this.page = 1;
				this.getaskindex();
			},
			todetail(id) {
				this.$router.push({
					path: `/recruitDetail`,
					query: {
						id: id,
					},
				});
			},
			topulbc() {
				var userInfo = localStorage.getItem("head_pic");
				if(!userInfo){
					this.$message({
						message: '请登录账号发布',
						type: "error",
					});
					return;
				}
				this.$router.push({
					path: `/recruitRelease`,
				});
			}
		},
	};
</script>
<style lang="less">
	.page {
		background: #f6f6f6;
		height: auto;

		.list {
			padding: 0 15px;
			margin-top: 60px;
			min-height: 60vh;

			.part {
				padding: 15px 0;
				border-bottom: 1px solid #ddd;

				.part-info {
					display: flex;
					align-items: center;
					margin-bottom: 10px;

					.left {
						width: 40px;
						height: 40px;
						border-radius: 40px;
						overflow: hidden;
						margin-right: 10px;

						img {
							width: 100%;
							height: 100%;
							display: block;
						}
					}

					.name {
						font-size: 16px;
						color: #666;
						font-weight: bold;
					}
				}

				.title {
					font-size: 16px;
					line-height: 1.6;
				}

				.img-list {
					margin: 20px 0 10px;
					flex-wrap: wrap;
					display: flex;
					display: -webkit-flex;
					align-items: center;
					-webkit-align-items: center;

					img {
						width: 99%;
						margin-bottom: 10px;
					}
				}

				.info {
					font-size: 14px;
					color: #999;

					span {
						display: inline-block;
						margin-right: 10px;
					}
				}
			}
		}

		.pages {
			position: relative;
			left: -15%;
			transform: scale(0.7);
			margin-top: 20px;
		}

		.dr-top {
			text-align: center;
			padding: 20px 0;
			margin-top: 40px;

			.dt-top-btn {
				width: 160px;
				height: 45px;
				background-color: #317ADA;
				border-radius: 6px;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				margin: 0 auto 20px;
			}

			.dt-top-text {
				font-size: 14px;
				color: #999;
			}
		}
	}
</style>